
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import SeparationLine from './SeparationLine.vue'
import { IHighlightBulletList } from '../shared/general/interfaces/IHighlightBulletList'
import { globalLabelAsString } from '../shared/general/services/StoreService'

@Component({
  name: 'HighlightBulletList',
  components: {
    SeparationLine,
    BaseButton: () => import('./base/BaseButton.vue'),
    BaseHeadline: () => import('./base/BaseHeadline.vue'),
    RichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class HighlightBulletList extends Vue {
  @Prop() headlineTag ?: 'h2' | 'h3'

  @Prop() headlineText ?: string

  @Prop({ default: () => [] }) listItems! : IHighlightBulletList[]

  @Prop({ default: false }) semanticHeadline! : boolean

  private minElements : number = 4

  private fullListVisible : boolean = false

  private toggleFullList () : void {
    this.fullListVisible = !this.fullListVisible
  }

  private get openLabel () : string {
    return globalLabelAsString(this.fullListVisible ? 'show_less_label' : 'show_more_label')
  }
}
